<!-- eslint-disable vue/no-v-html -->
<template>
	<div>
		<div class="row mt-4">
			<div class="col-12 d-flex">
				<div class="h2 text-muted py-3">
					{{ translate('checkout_failure_title') }}
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12">
				<ul class="list-group w-100">
					<li class="list-group-item">
						<div class="row">
							<div
								id="content"
								class="col-sm-12 text-center"
								style="margin: 30px 0 60px;">
								<i class="fas fa-exclamation-circle fa-5x text-warning" />
								<div class="success-message margin-20">
									<h3
										class="mt-4"
										v-html="translate('checkout_failure_subtitle')" />
									<hr>
									<p
										v-if="orderId"
										v-html="translate('checkout_failure_body', {support:companyEmail, support1:companyEmail})" />
								</div>
								<div class="clearfix text-center">
									<div class="container">
										<div class="col-xs-12">
											<div class="text-center">
												<b-button
													v-if="orderId"
													:disabled="loading"
													variant="primary"
													style="width: 200px"
													@click="openExternalPayment()">
													<template v-if="!loading">
														<i
															class="fa text-small fa-refresh"
															aria-hidden="true" />
														{{ translate('try_again') }}
													</template>
													<template v-else>
														<i class="fa fa-fw fa-spinner fa-pulse" /> {{ translate('processing') }}
													</template>
												</b-button>
												<b-button
													v-else
													variant="primary"
													style="width: 200px"
													@click="$router.push({ name: getRedirectNameByReplicated('Store') })">
													<i
														class="fa text-small fa-store"
														aria-hidden="true" />
													{{ translate('back_to_store') }}
												</b-button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</div>
		<div class="row">
			<div class="col-4 ml-auto my-4" />
		</div>
	</div>
</template>

<script>
import { Store, Validations } from '@/translations';
import DocumentTitle from '@/mixins/DocumentTitle';
import WindowSizes from '@/mixins/WindowSizes';
import PurchaseRedirects from '../mixins/PurchaseRedirects';
import AgencySite from '@/mixins/AgencySite';

export default {
	name: 'RedirectedPurchaseFailure',
	messages: [Store, Validations],
	mixins: [DocumentTitle, PurchaseRedirects, WindowSizes, AgencySite],
	data() {
		return {
			companyEmail: process.env.VUE_APP_COMPANY_EMAIL,
		};
	},
};
</script>
